import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Theme from "../ThemeContext";
import Footer from "./Footer";
import Header from "./Header";

const Layout = ({ children }) => (
  <Theme>
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              menuLinks {
                name
                link
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Header
            menuLinks={data.site.siteMetadata.menuLinks}
            siteTitle={data.site.siteMetadata.title}
          />
          {children}
          <Footer />
        </>
      )}
    />
  </Theme>
);

export default Layout;
