import "@fontsource/source-sans-pro";
import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    purple: "#471871",
    purpleSecondary: "#653496",
    purpleDark: "#280943",
    green: "#74B652",
    pink: "#FE3D76",
    blue: "#4354C5",
    yellow: "#F2C94C",
  },
  fonts: {
    primary: "Source Sans Pro",
    secondary: "Roboto",
    // alternate: 'Montserrat'
  },
  fontSizes: {
    xxs: "14px",
    xs: "16px",
    sm: "18px",
    md: "22px",
    lg: "28px",
    xl: "32px",
    xxl: "48px",
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
