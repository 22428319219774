import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import styled from "styled-components";

const Header = ({ siteTitle, menuLinks }) => {
  return (
    <Styles>
      <Navbar
        collapseOnSelect
        expand="lg"
        sticky="top"
        style={{
          backgroundColor: "white",
        }}
      >
        <Container>
          <Navbar.Brand href="/" as="div">
            <Link to={"/"}>
              <StaticImage
                src="../images/cet_logo.png"
                alt="The Children's Educational Trust logo"
                style={{ height: "50px", width: "79px" }}
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            style={{ border: "none" }}
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <SiteTitle>{siteTitle}</SiteTitle>
            <Nav className="ml-auto">
              {menuLinks.map((link) => (
                <MenuLinkItem
                  key={link.name}
                  to={link.link}
                  style={menuLinkItemStyle}
                  activeStyle={menuLinkItemActiveStyle}
                >
                  {link.name}
                </MenuLinkItem>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Styles>
  );
};

const Styles = styled.div`
  position: absolute;
  width: 100%;

  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(71,24,113, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='4' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
`;

const SiteTitle = styled.h1`
  font-size: ${({ theme: { fontSizes } }) => fontSizes.md};
  line-height: ${({ theme: { fontSizes } }) => fontSizes.md};
  color: ${({ theme: { colors } }) => colors.purple};
  display: flex;
  justify-content: center;
  font-weight: bold;
  max-width: 220px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

const MenuLinkItem = styled((props) => <Link {...props} />)`
  text-align: right;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.md};
  margin: 8px 0;
  @media (min-width: 992px) {
    font-size: ${({ theme: { fontSizes } }) => fontSizes.sm};
    margin: 0 8px;
  }
`;

const menuLinkItemStyle = {
  color: "#471871",
  padding: "2px 0",
  textDecoration: "none",
  fontWeight: "bold",
};

const menuLinkItemActiveStyle = {
  color: "#74B652",
  paddingBottom: "2px",
  borderBottom: "solid 2px",
};

export default Header;
