import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const Footer = () => {
  return (
    <FooterStyle>
      <Container>
        <Row>
          <Col xs="12" lg="4">
            <FooterImageWithText>
              <StaticImage
                src="../images/cet_logo.png"
                alt="The Children's Educational Trust logo"
                style={{ height: "80px", width: "126px" }}
              />
              <LogoText>The Children's Educational Trust</LogoText>
            </FooterImageWithText>
          </Col>
          <Col xs="12" lg="4">
            <ContactDetails>
              <h4>Call us on</h4>
              <a href="tel:+44 1625415403">+44 (0) 1625 415403</a>
              <h4 style={{ marginTop: "2rem" }}>Email us at</h4>
              <a href="mailto:support@thechildrenseducationaltrust.com">
                support@thechildrenseducationaltrust.com
              </a>
            </ContactDetails>
          </Col>
          <Col xs="12" lg="4" style={{ margin: "1rem 0" }}>
            <p>The Children’s Educational Trust</p>
            <p>
              2nd Floor, Park Gates <br />
              Bury New Road <br />
              Prestwich <br />
              M25 0TL
            </p>
            <p>
              Charity Registration No. 1137444
              <br />
              Company Registration No. 7448135
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <LegalNotice>
              Copyright © {new Date().getFullYear()}&nbsp;The Children's
              Educational Trust
            </LegalNotice>
          </Col>
        </Row>
      </Container>
    </FooterStyle>
  );
};

const FooterStyle = styled.footer`
  background-color: ${({ theme: { colors } }) => colors.purpleDark};
  color: white;
  padding: 1rem 0;
`;

const FooterImageWithText = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;
`;

const LogoText = styled.p`
  font-size: ${({ theme: { fontSizes } }) => fontSizes.md};
  line-height: ${({ theme: { fontSizes } }) => fontSizes.md};
  margin-bottom: 0;
  margin-left: 1rem;
`;

const ContactDetails = styled.div`
  margin: 1rem 0;
  h4 {
  }
  a {
    color: white;
  }
`;

const LegalNotice = styled.div`
  text-align: center;
`;

export default Footer;
